import {TeamInfo} from '../types/teams';

type SetState<T> = (value: T) => void;

const fetchTeams = async (setTeams: SetState<string[]>) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/team/teams`, {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data: string[] = await response.json();
      const filteredData = data.filter((team: string) => team !== 'Free Agent');
      setTeams(filteredData);
    } else {
      console.error('Error fetching teams');
    }
  } catch (error) {
    console.error('Error fetching teams:', error);
  }
};

const fetchAllTeams = async (setTeams: SetState<string[]>) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/team/teams`, {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data = await response.json();
      setTeams(data);
    } else {
      console.error('Error fetching all teams');
    }
  } catch (error) {
    console.error('Error fetching all teams:', error);
  }
};

const fetchTeamsPage = async (setTeams: SetState<TeamInfo[]>) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/team/teamsPage', {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data: TeamInfo[] = await response.json();
      setTeams(data);
    } else {
      console.error('Error fetching teams page');
    }
  } catch (error) {
    console.error('Error fetching teams page:', error);
  }
};

export {fetchTeams, fetchAllTeams, fetchTeamsPage};
