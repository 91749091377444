export interface Matchday {
  _id: string;
  teamA: string;
  teamB: string;
  status: string;
  matchday: number;
  wildcard: boolean;
  time: string;
  replay?: string;
  result?: string;
  scoreA?: number;
  scoreB?: number;
  statsA?: IndividualStats[];
  statsB?: IndividualStats[];
  defwin?: boolean;
}

interface IndividualStats {
  player: string;
  playtime: number;
  goal: number;
  assist: number;
  cleansheet: number;
}

export interface MatchdayDate {
  _id: string;
  matchday: number;
  date: string;
}

export interface PlayedMatch {
  teamAShort: string;
  teamALogo: string;
  teamBShort: string;
  teamBLogo: string;
  result: string;
  matchStatus: MatchStatus;
}

export enum MatchStatus {
  WIN = 'win',
  LOSE = 'lose',
  DRAW = 'draw',
}
