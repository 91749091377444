const fetchPlayers = async (setPlayers) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/player/players', {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data = await response.json();
      setPlayers(data);
    } else {
      console.error('Errore nel recupero dei dati dei giocatori');
    }
  } catch (error) {
    console.error('Errore nel recupero dei dati dei giocatori:', error);
  }
};

const fetchData = async (section, isLogged, setPlayersData, setSection, setLoading) => {
  try {
    if (section === 'PLAYERS') {
      let url = process.env.REACT_APP_BACKEND_URL + '/player/players'; // Assicurati di avere la route corretta nel tuo backend

      const response = await fetch(url, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setPlayersData(data);
      }
    } else if (section === 'ADMIN_PANEL') {
      if (!isLogged) {
        setSection('LOGIN');
      } else {
        // Do whatever Admin Panel is
      }
    }
  } catch (error) {
    console.error('Errore nel recupero dei dati:', error);
  } finally {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    /* setLoading(false); */
  }
};

const fetchTeams = async (setTeams) => {
  // Effettua una richiesta al backend per ottenere la lista delle squadre
  fetch(process.env.REACT_APP_BACKEND_URL + '/team/teams', {
    headers: {
      'ngrok-skip-browser-warning': 'true',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // Rimuovi il team "Free Agent" dall'array data
      const filteredData = data.filter((team) => team !== 'Free Agent');

      // Mappa il risultato filtrato in oggetti da utilizzare con setTeams
      const teamsData = filteredData.map((team) => ({value: team, label: team}));

      setTeams(teamsData);
    })
    .catch((error) => console.error('Errore nel recupero delle squadre:', error));
};

const fetchAllTeams = async (setTeams) => {
  // Effettua una richiesta al backend per ottenere la lista delle squadre
  fetch(process.env.REACT_APP_BACKEND_URL + '/team/teams', {
    headers: {
      'ngrok-skip-browser-warning': 'true',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // Mappa il risultato filtrato in oggetti da utilizzare con setTeams
      const teamsData = data.map((team) => ({value: team, label: team}));

      setTeams(teamsData);
    })
    .catch((error) => console.error('Errore nel recupero delle squadre:', error));
};

const fetchPlayersByTeam = async (teamX, setPlayersTeamX, setStatsTeamX) => {
  // Effettua una richiesta al backend per ottenere la lista dei giocatori del team A
  fetch(`${process.env.REACT_APP_BACKEND_URL}/player/playersByTeam/${teamX.value}`, {
    headers: {
      'ngrok-skip-browser-warning': 'true',
    },
  })
    .then((response) => response.json())
    .then((data) => setPlayersTeamX(data.map((player) => ({value: player, label: player}))))
    .catch((error) => console.error('Errore nel recupero dei giocatori:', error));

  // Inizializza le statistiche del team A quando selezioni un nuovo team
  setStatsTeamX({});
};

const fetchPlayersStatsByTeam = async (teamX, setPlayersAndStats) => {
  // Effettua una richiesta al backend per ottenere la lista dei giocatori del team A
  fetch(`${process.env.REACT_APP_BACKEND_URL}/player/playersStatsByTeam/${teamX}`, {
    headers: {
      'ngrok-skip-browser-warning': 'true',
    },
  })
    .then((response) => response.json())
    .then((data) => setPlayersAndStats(data))
    .catch((error) => console.error('Errore nel recupero dei giocatori:', error));

  // Inizializza le statistiche del team A quando selezioni un nuovo team
};

const fetchPlayersWithNoTeam = async (setPlayersWithoutTeam) => {
  fetch(process.env.REACT_APP_BACKEND_URL + '/player/playersWithoutTeam', {
    headers: {
      'ngrok-skip-browser-warning': 'true',
    },
  })
    .then((response) => response.json())
    .then((data) => setPlayersWithoutTeam(data))
    .catch((error) => console.error('Errore nel recupero dei giocatori senza squadra:', error));
};

const fetchPlayedMatchesByTeam = async (teamX, setPlayedMatches) => {
  // Effettua una richiesta al backend per ottenere la lista dei giocatori del team A
  fetch(`${process.env.REACT_APP_BACKEND_URL}/match/playedMatchesByTeam/${teamX}`, {
    headers: {
      'ngrok-skip-browser-warning': 'true',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setPlayedMatches(data);
    })
    .catch((error) => console.error('Errore nel recupero dei giocatori:', error));

  // Inizializza le statistiche del team A quando selezioni un nuovo team
};

const fetchPlayoffMatches = async (setQuarterFinals, setSemiFinals, setFinal, setLoading) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/match/playoffs', {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data = await response.json();
      const quarterFinals = data.filter((object) => object.knockOutPhase === 'quarterfinal');
      const semiFinals = data.filter((object) => object.knockOutPhase === 'semifinal');
      const final = data.filter((object) => object.knockOutPhase === 'final');
      setQuarterFinals(quarterFinals);
      setSemiFinals(semiFinals);
      setFinal(final);
      setLoading(false);
    } else {
      console.error('Errore nel recupero dei dati Playoffs');
    }
  } catch (error) {
    console.error('Errore nel recupero dei dati Playoffs:', error);
  }
};

export default {
  fetchPlayers,
  fetchData,
  fetchTeams,
  fetchPlayersByTeam,
  fetchPlayersWithNoTeam,
  fetchAllTeams,
  fetchPlayersStatsByTeam,
  fetchPlayedMatchesByTeam,
  fetchPlayoffMatches,
};
