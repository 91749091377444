import {Matchday, MatchdayDate, PlayedMatch} from '../types/matchday';

type SetState<T> = (value: T) => void;

const fetchMatchdays = async (setMatchdays: SetState<Matchday[]>, setMatchdayDates: SetState<MatchdayDate[]>) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/match/matchdays`, {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data: Matchday[] = await response.json();
      setMatchdays(data);
    } else {
      console.error('Error fetching matchdays');
    }

    const response2 = await fetch(`${process.env.REACT_APP_BACKEND_URL}/match/matchdayDates`, {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response2.ok) {
      const data: MatchdayDate[] = await response2.json();
      setMatchdayDates(data);
    }
  } catch (error) {
    console.error('Error fetching matchdays:', error);
  }
};

const fetchPlayedMatchesByTeam = async (teamX: string, setPlayedMatches: SetState<PlayedMatch[]>) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/match/playedMatchesByTeam/${teamX}`, {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });

    if (response.ok) {
      const data: PlayedMatch[] = await response.json();
      setPlayedMatches(data);
    } else {
      console.error('Error fetching played matches by team');
    }
  } catch (error) {
    console.error('Error fetching played matches by team:', error);
  }
};

export {fetchMatchdays, fetchPlayedMatchesByTeam};
