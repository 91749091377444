import React from 'react';
import AddPlayerBox from '../components/AddPlayerBox';
import AddTeamBox from '../components/AddTeamBox';
import AddMatchBox from '../components/AddMatchBox';
import AddTOTW from '../components/AddTOTW';
import TransferPlayerBox from '../components/TransferPlayer';
import ChangePlayerCostBox from '../components/ChangePlayerCost';
import styled from 'styled-components';

const AdminContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  align-items: flex-start;
`;

const AdminBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const AdminPanel = () => {
  return (
    <AdminContainer>
      <AdminBox>
        <AddPlayerBox />
        <TransferPlayerBox />
        <ChangePlayerCostBox />
      </AdminBox>
      <AdminBox>
        <AddMatchBox />
      </AdminBox>
      <AdminBox>
        <AddTeamBox />
        <AddTOTW />
      </AdminBox>
    </AdminContainer>
  );
};

export default AdminPanel;
