import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faPersonRunning,
  faCoins,
  faFutbol,
  faHandshake,
  faMitten,
  faStopwatch,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'; // Sostituisci con le icone appropriate
import './index.css';

const MiniMatch = ({teamAShort, teamBShort, logoAName, logoBName, result, status}) => {
  let actualLogoAName = logoAName;
  let actualLogoBName = logoBName;

  if (actualLogoAName === 'Bastard_München.png') actualLogoAName = 'Bastard_Munchen.png';
  if (actualLogoBName === 'Bastard_München.png') actualLogoBName = 'Bastard_Munchen.png';

  return (
    <div className="mini-match">
      <div className="left-team2">
        <div className="mini-logo2-left">
          <img src={`/teamLogos/${actualLogoAName}`} alt={`Logo`} />
        </div>
        <div className="short-name2">{teamAShort}</div>
      </div>
      {status === 'win' ? (
        <div className="result2-win">{result}</div>
      ) : status === 'lose' ? (
        <div className="result2-lose">{result}</div>
      ) : (
        <div className="result2-draw">{result}</div>
      )}
      <div className="right-team2">
        <div className="short-name2">{teamBShort}</div>
        <div className="mini-logo2-right">
          <img src={`/teamLogos/${actualLogoBName}`} alt={`Logo`} />
        </div>
      </div>
    </div>
  );
};

export default MiniMatch;
