import React, {useEffect, useState} from 'react';
import {faCoins, faUsers, faCalendarAlt, faTrophy} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import {CardInfo, InfoBox, InfoItem, InfoIcon, InfoItemContainer, InfoItemSpan} from './card.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes, faMinus} from '@fortawesome/free-solid-svg-icons';
import {fetchPlayedMatchesByTeam} from '../actions/matchdays.ts';
import {MatchStatus, PlayedMatch} from '../types/matchday.ts';
import captainIconPath from '../captain_icon.png';

const Card = styled.div`
  background-color: #333;
  background: linear-gradient(151deg, #333 23.32%, rgba(33, 12, 120, 0.1) 137.29%);
  color: white;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 20px;
  margin: 15px;
  box-shadow: 0px 0px 10px 5px #222222e6;
  text-align: center;
  box-sizing: border-box;
  height: auto;
  overflow: visible;
  width: 100%;

  &:hover {
    transition: 0.2s;
    box-shadow: 0px 0px 12px 7px #222222e6;
  }

  @media (max-width: 768px) {
    padding: 15px;
    margin: 10px;
  }

  @media (max-width: 500px) {
    margin: 5px;
    padding: 10px;
  }
`;

const TeamNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 30px;

  @media (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const TeamName = styled.div`
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
`;

const TeamTitle = styled.h3`
  padding: 5px;
  border-radius: 6px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  @media (max-width: 500px) {
    font-size: 1.2rem;
    padding: 3px;
  }
`;

const PlayersList = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left;
  padding-left: 10px;

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin: 8px 0;
    font-size: 1rem;

    @media (max-width: 500px) {
      font-size: 0.9rem;
      margin: 5px 0;
    }
  }

  @media (max-width: 500px) {
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const PlayersTitle = styled.div`
  margin-bottom: 2px;
  font-weight: bold;

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
`;

const CaptainIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-left: 4px;
  vertical-align: middle;
`;

const TeamInfoItemContainer = styled(InfoItemContainer)`
  box-shadow: 0px 0px 10px 5px #222222e6;
  background-color: #5b5a5a;
  border-radius: 6px;
  padding: 3px;
  vertical-align: middle;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    padding: 2px;
    font-size: 0.8rem;
  }
`;

const LastMatches = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
  text-align: center;

  @media (max-width: 460px) {
    display: none;
  }

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const LastMatchesText = styled.h4`
  font-size: 0.7rem;
  text-decoration: none;
`;

const MatchCircle = styled.div<{$matchStatus: string}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => {
    switch (props.$matchStatus) {
      case 'win':
        return 'green';
      case 'lose':
        return 'red';
      case 'draw':
        return 'gray';
      default:
        return 'lightgray';
    }
  }};

  @media (max-width: 500px) {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`;

const MatchIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: white;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

const LastFiveMatches = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PlayersAndMatchesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface TeamCardProps {
  teamName: string;
  shortName: string;
  players: string[]; // Replace 'any[]' with the actual player type, if known
  foundationDate: string;
  totalCost: number;
  logoFileName: string;
  leaderboardPosition: number;
  onClick: (shortName: string) => void; // Function to handle click events
}

const TeamCard: React.FC<TeamCardProps> = ({
  teamName,
  shortName,
  players,
  foundationDate,
  totalCost,
  logoFileName,
  leaderboardPosition,
  onClick,
}) => {
  // const captainIconPath = require('../captain_icon.png');
  const date = new Date(foundationDate);
  const month = date.getMonth() + 1;
  const dateStr = date.getDate() + '/' + month + '/' + date.getFullYear();

  let actualLogoFileName = logoFileName;
  if (actualLogoFileName === 'Bastard_München.png') actualLogoFileName = 'Bastard_Munchen.png';

  const [playedMatches, setPlayedMatches] = useState<PlayedMatch[]>([]);

  useEffect(() => {
    fetchPlayedMatchesByTeam(teamName, setPlayedMatches);
  }, [teamName]);

  const handleClick = () => {
    onClick(shortName);
  };

  const isPlayerLenghtAboveZero = players.length > 0;

  return (
    <Card onClick={handleClick}>
      <TeamNameContainer>
        <TeamName>
          <TeamTitle>{teamName}</TeamTitle>
        </TeamName>
      </TeamNameContainer>
      <CardInfo>
        <InfoBox>
          <TeamInfoItemContainer>
            <InfoItem>
              <InfoIcon icon={faUsers} />
              <InfoItemSpan>{players.length}</InfoItemSpan>
            </InfoItem>
            <InfoItem>
              <InfoIcon icon={faCoins} />
              <InfoItemSpan>{totalCost}</InfoItemSpan>
            </InfoItem>
            <InfoItem>
              <InfoIcon icon={faTrophy} />
              <InfoItemSpan>{leaderboardPosition + '°'}</InfoItemSpan>
            </InfoItem>
            <InfoItem>
              <InfoIcon icon={faCalendarAlt} />
              <InfoItemSpan>{dateStr}</InfoItemSpan>
            </InfoItem>
          </TeamInfoItemContainer>
        </InfoBox>
      </CardInfo>

      <PlayersAndMatchesContainer>
        {isPlayerLenghtAboveZero ? (
          <PlayersList>
            <PlayersTitle>
              <strong>Players:</strong>
            </PlayersTitle>
            <ul>
              {players.map((player, index) => (
                <li key={index}>
                  {index === 0 ? (
                    <>
                      {player}
                      <CaptainIcon src={captainIconPath} alt="Captain Icon" />
                    </>
                  ) : (
                    player
                  )}
                </li>
              ))}
            </ul>
          </PlayersList>
        ) : null}

        <LastMatches>
          <LastMatchesText>Last Matches</LastMatchesText>
          <LastFiveMatches>
            {playedMatches.slice(0, 5).map((match, index) => (
              <MatchCircle key={index} $matchStatus={match.matchStatus}>
                <MatchIcon
                  icon={
                    match.matchStatus === MatchStatus.WIN
                      ? faCheck
                      : match.matchStatus === MatchStatus.LOSE
                        ? faTimes
                        : faMinus
                  }
                />
              </MatchCircle>
            ))}
          </LastFiveMatches>
        </LastMatches>
      </PlayersAndMatchesContainer>
    </Card>
  );
};

export default TeamCard;
