import {
  AssistLeaderboardType,
  CleansheetLeaderboardType,
  GoalLeaderboardType,
  LeagueLeaderboardType,
} from '../types/leaderboard';

type SetState<T> = (value: T) => void;

const fetchLeaderboards = async (
  setLeagueLeaderboardData: SetState<LeagueLeaderboardType[]>,
  setGoalLeaderboardData: SetState<GoalLeaderboardType[]>,
  setAssistLeaderboardData: SetState<AssistLeaderboardType[]>,
  setCleansheetLeaderboardData: SetState<CleansheetLeaderboardType[]>
) => {
  try {
    const [leagueResponse, goalResponse, assistResponse, cleansheetResponse] = await Promise.all([
      fetch(`${process.env.REACT_APP_BACKEND_URL}/leaderboard/league`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      }),
      fetch(`${process.env.REACT_APP_BACKEND_URL}/leaderboard/goal`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      }),
      fetch(`${process.env.REACT_APP_BACKEND_URL}/leaderboard/assist`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      }),
      fetch(`${process.env.REACT_APP_BACKEND_URL}/leaderboard/cleansheet`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      }),
    ]);

    if (leagueResponse.ok && goalResponse.ok && assistResponse.ok && cleansheetResponse.ok) {
      const [leagueData, goalData, assistData, cleansheetData]: [
        LeagueLeaderboardType[],
        GoalLeaderboardType[],
        AssistLeaderboardType[],
        CleansheetLeaderboardType[],
      ] = await Promise.all([
        leagueResponse.json(),
        goalResponse.json(),
        assistResponse.json(),
        cleansheetResponse.json(),
      ]);

      setLeagueLeaderboardData(leagueData);
      setGoalLeaderboardData(goalData);
      setAssistLeaderboardData(assistData);
      setCleansheetLeaderboardData(cleansheetData);
    } else {
      console.error('Error fetching one or more leaderboards');
    }
  } catch (error) {
    console.error('Error fetching leaderboards:', error);
  }
};

export {fetchLeaderboards};
