import React from 'react';
import styled from 'styled-components';
import {themePalette} from '../themePalette.ts';
import {Matchday, MatchdayDate} from '../types/matchday.ts';
import {TeamInfo} from '../types/teams.ts';

const TableContainer = styled.div`
  margin: 30px;
  margin-top: 50px;
  width: 90%;
  max-width: 1000px;

  overflow-x: auto; //Use overflow-x for horizontal scrolling
  white-space: nowrap;

  @media (max-width: 850px) {
    margin-top: 30px;
  }

  @media (max-width: 770px) {
    margin: 10px;
    width: 100%;
  }
`;

const Table = styled.table`
  overflow: hidden;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${themePalette.dark.surface};
`;

const TableHeader = styled.thead`
  background-color: ${themePalette.dark.surfaceContainerHigh};
  color: ${themePalette.dark.onSurface};
  font-weight: 500;
`;

const TableCell = styled.td`
  padding: 12px 15px;
  text-align: center;
  border-bottom: 0.5px solid ${themePalette.dark.onSecondaryContainer};
  font-size: 0.9rem;

  @media (max-width: 710px) {
    padding: 10px 10px;
    font-size: 0.8rem;
  }
`;

const TableRow = styled.tr.attrs<{$result: string}>((props) => ({
  $result: props.$result || undefined,
}))`
  color: ${(props) => {
    if (props.$result === 'win') return themePalette.dark.onBackground;
    if (props.$result === 'lose') return themePalette.dark.onErrorContainer;
    if (props.$result === 'draw') return themePalette.dark.onTertiaryContainer;
    return themePalette.dark.onSurfaceVariant;
  }};

  background-color: ${(props) => {
    if (props.$result === 'win') return themePalette.dark.successContainer;
    if (props.$result === 'lose') return themePalette.dark.errorContainer;
    if (props.$result === 'draw') return themePalette.dark.tertiaryContainer;
    return themePalette.dark.surfaceVariant;
  }};
`;

const TeamNameLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;

  @media (max-width: 880px) {
    margin-left: 0px;
  }

  @media (max-width: 710px) {
    justify-content: center;
  }
`;

const TeamLogo = styled.img`
  vertical-align: middle;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 8px;

  @media (max-width: 710px) {
    margin-right: 0px;
    width: 40px;
    height: 40px;
  }
`;

const FullMatchday = styled.span`
  @media (max-width: 930px) {
    display: none;
  }
`;

const ShortMatchday = styled.span`
  display: none;

  @media (max-width: 930px) {
    display: inline;
  }
`;

const FullName = styled.span`
  @media (max-width: 710px) {
    display: none;
  }
`;

interface TeamSchedulePageProps {
  groupedMatches: Record<number, Matchday[]>;
  matchdayDates: MatchdayDate[];
  team: TeamInfo;
}

function renderTeamNameLogo(teamName: string) {
  let teamLogoFileName = teamName.replace(/\s/g, '_');
  if (teamLogoFileName === 'Bastard_München') teamLogoFileName = 'Bastard_Munchen';

  return (
    <TeamNameLogo>
      <TeamLogo src={`/teamLogos/${teamLogoFileName}.png`} alt={`${teamName} Logo`} />
      <FullName>{teamName}</FullName>
    </TeamNameLogo>
  );
}

function getResult(teamA: string, currentTeam: TeamInfo, result?: string): string {
  if (!result) return '';
  const [teamAResult, teamBResult] = result.split('-');

  if (teamA === currentTeam.name) {
    return Number(teamAResult) > Number(teamBResult)
      ? 'win'
      : Number(teamAResult) < Number(teamBResult)
        ? 'lose'
        : 'draw';
  } else {
    return Number(teamAResult) < Number(teamBResult)
      ? 'win'
      : Number(teamAResult) > Number(teamBResult)
        ? 'lose'
        : 'draw';
  }
}

const TeamSchedulePage: React.FC<TeamSchedulePageProps> = ({groupedMatches, matchdayDates, team}) => {
  const renderMatches = () => {
    return Object.entries(groupedMatches).flatMap(([matchday, matches]) =>
      matches
        .filter((match) => match.teamA === team.name || match.teamB === team.name)
        .map((match) => (
          <TableRow key={matchday} $result={getResult(match.teamA, team, match.result)}>
            <TableCell>{matchday}</TableCell>
            <TableCell>{matchdayDates.find((date) => date.matchday === Number(matchday))?.date || null}</TableCell>
            <TableCell>{match.time}</TableCell>
            <TableCell>{renderTeamNameLogo(match.teamA)}</TableCell>
            <TableCell>{match.result && match.result !== '' ? match.result : '-'}</TableCell>
            <TableCell>{renderTeamNameLogo(match.teamB)}</TableCell>
          </TableRow>
        ))
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <tr>
            <TableCell>
              <FullMatchday>Matchday</FullMatchday>
              <ShortMatchday>MD</ShortMatchday>
            </TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Home</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Away</TableCell>
          </tr>
        </TableHeader>
        <tbody>{renderMatches()}</tbody>
      </Table>
    </TableContainer>
  );
};

export default TeamSchedulePage;
