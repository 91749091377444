// SpecificPlayerPage.js
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark, faFutbol, faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import PlayerStats from '../../components/PlayerStats';
import './index.css';

const SpecificPlayerPage = ({playerName, playerStats, onClick}) => {
  return (
    <div className="specific-player-page">
      <div className="specific-player-container">
        <div className="specific-player-header">
          <div className="logo-name-container">
            <div className="specific-team-logo">
              <img src={`/teamLogos/sdffd`} alt={`Logo`} className="team-logo" />
            </div>
            <div className="specific-player-name">{playerName}</div>
          </div>
          <div className="exit-button">
            <FontAwesomeIcon icon={faXmark} className="exit-button2" onClick={() => onClick(false)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecificPlayerPage;
