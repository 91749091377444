import React from 'react';

import styled from 'styled-components';
import {themePalette} from '../themePalette.ts';
import {GoalLeaderboardType} from '../types/leaderboard.ts';

const GoalLeaderboardContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 60px;

  @media (max-width: 1170px) {
    gap: 0px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  width: 90%;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const ScrollingTableContainer = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${themePalette.dark.surface};
  margin-bottom: 20px;
`;

const TableHeader = styled.thead`
  background-color: ${themePalette.dark.surfaceContainerHigh};
  color: ${themePalette.dark.onSurface};
`;

const TableRow = styled.tr`
  color: ${themePalette.dark.onSurfaceVariant};
  background-color: ${themePalette.dark.surfaceVariant};

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  text-align: center;
  border-bottom: 0.5px solid ${themePalette.dark.onSecondaryContainer};
  font-size: 0.9rem;
`;

const NotAvailable = styled.div`
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${themePalette.dark.surfaceVariant};
  color: ${themePalette.dark.onSurfaceVariant};
  padding: 12px 15px;
`;

const TitleHeader = styled.div`
  padding: 10px;
  border-radius: 8px 8px 0 0;
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
`;

const TeamNameLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const TeamLogo = styled.img`
  vertical-align: middle;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

const FullName = styled.span`
  @media (max-width: 580px) {
    display: none;
  }
`;

function renderTeamNameLogo(teamName: string) {
  let teamLogoFileName = teamName.replace(/\s/g, '_');
  if (teamLogoFileName === 'Bastard_München') teamLogoFileName = 'Bastard_Munchen';

  return (
    <TeamNameLogo>
      <TeamLogo src={`/teamLogos/${teamLogoFileName}.png`} alt={`${teamName} Logo`} />
      <FullName>{teamName}</FullName>
    </TeamNameLogo>
  );
}

interface GoalLeaderboardProps {
  classifica: GoalLeaderboardType[];
}

const GoalLeaderboard: React.FC<GoalLeaderboardProps> = ({classifica}) => {
  const colors = {
    'First pos': '#4185F3',
    'Second pos': '#FB7B18',
    'Third pos': '#33A854',
    Rest: themePalette.dark.surfaceVariant,
  };

  const goalPerMatchLeaderboard = classifica
    .slice()
    .filter((player) => player.matches !== 0 && player.goal > 0)
    .sort((a, b) => {
      if (b.matches > 0 && a.matches > 0) {
        return b.goal / b.matches - a.goal / a.matches;
      } else return 0;
    });

  return (
    <GoalLeaderboardContainer>
      <Container>
        <TitleHeader>Goal Leaderboard</TitleHeader>
        <TableContainer>
          <ScrollingTableContainer>
            {classifica.length > 0 ? (
              <Table>
                <TableHeader>
                  <tr>
                    <TableCell>Pos</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>Gls</TableCell>
                    <TableCell>M</TableCell>
                  </tr>
                </TableHeader>
                <tbody>
                  {classifica.map((player, index) => (
                    <TableRow
                      key={index}
                      style={{
                        boxShadow: `inset 6px 0px 0px ${
                          colors[
                            index === 0 ? 'First pos' : index === 1 ? 'Second pos' : index === 2 ? 'Third pos' : 'Rest'
                          ]
                        }`,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{player.name}</TableCell>
                      <TableCell>{renderTeamNameLogo(player.team)}</TableCell>
                      <TableCell>{player.goal}</TableCell>
                      <TableCell>{player.matches}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NotAvailable>No data available for Goal Leaderboard at the moment.</NotAvailable>
            )}
          </ScrollingTableContainer>
        </TableContainer>
      </Container>
      <Container>
        <TitleHeader>Goal Per Match Leaderboard</TitleHeader>
        <TableContainer>
          <ScrollingTableContainer>
            {goalPerMatchLeaderboard.length > 0 ? (
              <Table>
                <TableHeader>
                  <tr>
                    <TableCell>Pos</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>Gls/M</TableCell>
                  </tr>
                </TableHeader>
                <tbody>
                  {goalPerMatchLeaderboard.map((player, index) => (
                    <TableRow
                      key={index}
                      style={{
                        boxShadow: `inset 6px 0px 0px ${
                          colors[
                            index === 0 ? 'First pos' : index === 1 ? 'Second pos' : index === 2 ? 'Third pos' : 'Rest'
                          ]
                        }`,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{player.name}</TableCell>
                      <TableCell>{renderTeamNameLogo(player.team)}</TableCell>
                      <TableCell>{player.matches !== 0 ? (player.goal / player.matches).toFixed(2) : 0}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NotAvailable>No data available for Goal Leaderboard at the moment.</NotAvailable>
            )}
          </ScrollingTableContainer>
        </TableContainer>
      </Container>
    </GoalLeaderboardContainer>
  );
};

export default GoalLeaderboard;
