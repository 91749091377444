// MobileNav.tsx
import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faPersonRunning,
  faPeopleGroup,
  faRankingStar,
  faCalendarDays,
  faScrewdriverWrench,
  faAward,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import {faDiscord} from '@fortawesome/free-brands-svg-icons';
import {mobileBreakpoint} from './navbarHelpers.tsx';
import {topBarHeight} from './navbarHelpers.tsx';
import {themePalette} from '../../themePalette.ts';

interface MobileNavProps {
  setSection: (section: string) => void;
  activeSection: string;
  toggleMenu: () => void;
}

const NavLinksMobile = styled.ul`
  position: fixed;
  top: ${topBarHeight}px;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: #004f58;
  z-index: 1000;
  overflow-y: auto;

  @media (min-width: ${mobileBreakpoint + 1}px) {
    display: none;
  }
`;

const NavItem = styled.li`
  color: ${themePalette.dark.onPrimaryContainer};
  cursor: pointer;
  padding: 15px 15px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  &.active {
    background-color: ${themePalette.dark.primary};
    color: ${themePalette.dark.onPrimary};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  margin-left: 8px;
  font-size: 24px;
`;

const DiscordButton = styled.a`
  color: white;
  background-color: #6e78f7;
  padding: 15px 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-decoration: none;
`;

const MobileNav: React.FC<MobileNavProps> = ({setSection, activeSection, toggleMenu}) => {
  const handleSectionChange = (newSection: string) => {
    toggleMenu();
    setSection(newSection);
  };

  return (
    <NavLinksMobile>
      <NavItem onClick={() => handleSectionChange('TEAMS')} className={activeSection === 'TEAMS' ? 'active' : ''}>
        <StyledIcon icon={faPeopleGroup} />
        <span>Teams</span>
      </NavItem>
      <NavItem onClick={() => handleSectionChange('PLAYERS')} className={activeSection === 'PLAYERS' ? 'active' : ''}>
        <StyledIcon icon={faPersonRunning} />
        <span>Players</span>
      </NavItem>
      <NavItem onClick={() => handleSectionChange('SCHEDULE')} className={activeSection === 'SCHEDULE' ? 'active' : ''}>
        <StyledIcon icon={faCalendarDays} />
        <span>Schedule</span>
      </NavItem>
      <NavItem onClick={() => handleSectionChange('TOTW')} className={activeSection === 'TOTW' ? 'active' : ''}>
        <StyledIcon icon={faAward} />
        <span>Team of the Week</span>
      </NavItem>
      <NavItem onClick={() => handleSectionChange('PLAY_OFF')} className={activeSection === 'PLAY_OFF' ? 'active' : ''}>
        <StyledIcon icon={faStar} />
        <span>Play Off</span>
      </NavItem>
      <NavItem
        onClick={() => handleSectionChange('ADMIN_PANEL')}
        className={activeSection === 'ADMIN_PANEL' || activeSection === 'LOGIN' ? 'active' : ''}
      >
        <StyledIcon icon={faScrewdriverWrench} />
        <span>Admin Panel</span>
      </NavItem>
      <NavItem
        onClick={() => handleSectionChange('LEADERBOARD')}
        className={activeSection === 'LEADERBOARD' ? 'active' : ''}
      >
        <StyledIcon icon={faRankingStar} />
        <span>Leaderboard</span>
      </NavItem>
      <DiscordButton href="https://discord.com/invite/My6XSb4tS3">
        <StyledIcon icon={faDiscord} />
        Join our Discord
      </DiscordButton>
    </NavLinksMobile>
  );
};

export default MobileNav;
