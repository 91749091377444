import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoItemContainer = styled.div`
  display: flex;
`;

export const InfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px;
  color: ${(props) => props.color || 'inherit'};
`;

export const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  margin-bottom: 4px;
`;

export const InfoItemSpan = styled.span`
  font-size: 0.9rem;
`;
