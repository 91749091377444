// TeamOfTheWeekCard.js
import React from 'react';
import {AutoTextSize} from 'auto-text-size';
import styled from 'styled-components';

const TeamCardContainer = styled.div`
  background-color: #333;
  color: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 150px;
  height: 220px;
  flex-shrink: 0;

  &:hover {
    transition: 0.4s;
    transform: translateY(-10px);
  }

  div {
    margin-bottom: 8px;
  }
`;

const TeamOfTheSeasonCardContainer = styled(TeamCardContainer)`
  background-image: linear-gradient(to right bottom, #810c37, #b30e33, #cf0d2f, #dd0d2e, #f50d2b);

  &:hover {
    box-shadow: 0px 0px 10px 5px #fa0505;
  }
`;

const TeamOfTheWeekCardContainer = styled(TeamCardContainer)`
  background-image: linear-gradient(to right bottom, #392f8c, #1b4fbf, #009cdf, #00acc1, #39b8a7);

  &:hover {
    box-shadow: 0px 0px 10px 5px #009cdf;
  }
`;

const TopRoleLogo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopLeft = styled.div`
  font-size: 1.2rem;
  font-weight: bolder;
  padding-top: 5px;
  padding-left: 5px;
`;

const TeamLogoContainer = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AvatarCircle = styled.div`
  width: 80px;
  height: 80px;
  border: 6px solid white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayerAvatar = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  font-size: 40px;
  text-align: center;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayerNameContainer = styled.div`
  padding-top: 25px;
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const TeamOfTheWeekCard = ({role, teamLogo, playerAvatar, playerName, selectedWeek}) => {
  let actualTeamLogo = teamLogo;
  if (actualTeamLogo === 'Bastard_München.png') actualTeamLogo = 'Bastard_Munchen.png';

  const CardComponent = selectedWeek === 'TOTS' ? TeamOfTheSeasonCardContainer : TeamOfTheWeekCardContainer;

  /* console.log(teamLogoFileName);*/
  /*  const teamLogoPath = require("/teamLogos/" + teamLogo); */

  return (
    <CardComponent>
      <TopRoleLogo>
        <TopLeft>{role}</TopLeft>
        <TeamLogoContainer>
          <img src={'/teamLogos/' + actualTeamLogo} alt="Team Logo" />
        </TeamLogoContainer>
      </TopRoleLogo>
      <AvatarContainer>
        <AvatarCircle>
          <PlayerAvatar>{playerAvatar}</PlayerAvatar>
        </AvatarCircle>
      </AvatarContainer>
      <PlayerNameContainer>
        <AutoTextSize maxFontSizePx={16} mode="shrink" minFontSizePx={10} fontSizePrecisionPx={1}>
          {playerName}
        </AutoTextSize>
      </PlayerNameContainer>
    </CardComponent>
  );
};

export default TeamOfTheWeekCard;
