import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar/navbar.tsx';
import Login from './pages/Login/index.js';
import AdminPanel from './pages/adminPanel.tsx';
import PlayersPage from './pages/PlayersPage/index.js';
import SchedulePage from './pages/schedulePage.tsx';

import './components/AddPlayerBox/index.js';
import TeamsPage from './pages/teamsPage.tsx';
import TeamOfTheWeekPage from './pages/TeamOfTheWeekPage/index.js';
import Loading from './components/Loading/index.js';

import actions from './actions/index.js';
import PlayOffPage from './pages/PlayOffPage/index.js';
import styled, {createGlobalStyle} from 'styled-components';
import MobileNav from './components/Navbar/mobileNavbar.tsx';
import {themePalette} from './themePalette.ts';
import Leaderboard from './pages/leaderboard.tsx';

const ContentVisibility = {
  HOME: 'HOME',
  PLAYERS: 'PLAYERS',
  TEAMS: 'TEAMS',
  TOTW: 'TOTW',
  LEADERBOARD: 'LEADERBOARD',
  LOGIN: 'LOGIN',
  ADMIN_PANEL: 'ADMIN_PANEL',
  SCHEDULE: 'SCHEDULE',
  PLAY_OFF: 'PLAY_OFF',
};

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${themePalette.dark.background};
  }
`;

const AppContent = styled.div`
  height: 100%;

  display: grid;
  grid-template-areas:
    'nav-bar'
    'app-frame';
  grid-template-rows: auto 1fr;
  background-color: ${themePalette.dark.background};
  color: ${themePalette.dark.onBackground};
  font-family: 'Roboto', sans-serif;
`;

const StyledTopBar = styled(Navbar)`
  grid-area: nav-bar;
`;

const AppFrame = styled.div`
  grid-area: app-frame;
  overflow: auto;
`;

function App() {
  const [section, setSection] = useState(ContentVisibility.LEADERBOARD);
  const [playersData, setPlayersData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isLogged, setIsLogged] = useState(false);
  const [globalUsername, setGlobalUsername] = useState([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleContentChange = (content) => {
    setSection(content);
    setIsMenuOpen(false); //close menu when a link is clicked.
  };

  useEffect(() => {
    setLoading(true);
    actions.fetchData(section, isLogged, setPlayersData, setSection, setLoading);
  }, [section, isLogged]);

  const handleAppFrameClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <AppContent>
      <StyledTopBar
        setSection={handleContentChange}
        activeSection={section}
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
      />
      {isMenuOpen && <MobileNav setSection={handleContentChange} activeSection={section} toggleMenu={toggleMenu} />}
      <AppFrame onClick={handleAppFrameClick}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {section === ContentVisibility.LEADERBOARD && <Leaderboard />}
            {section === ContentVisibility.PLAYERS && <PlayersPage players={playersData} />}
            {section === ContentVisibility.TEAMS && <TeamsPage teams={playersData} />}
            {section === ContentVisibility.TOTW && <TeamOfTheWeekPage />}
            {section === ContentVisibility.LOGIN && (
              <Login setIsLogged={setIsLogged} setSection={handleContentChange} setGlobalUsername={setGlobalUsername} />
            )}
            {section === ContentVisibility.ADMIN_PANEL && <AdminPanel />}
            {section === ContentVisibility.SCHEDULE && <SchedulePage />}
            {section === ContentVisibility.PLAY_OFF && <PlayOffPage />}
          </>
        )}
      </AppFrame>
    </AppContent>
    </>
  );
}

export default App;
