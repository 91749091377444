import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faPersonRunning,
  faPeopleGroup,
  faRankingStar,
  faCalendarDays,
  faScrewdriverWrench,
  faAward,
  faStar,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {faDiscord} from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import {
  topBarHeight,
  topBarButtonGap,
  topBarHorizontalPadding,
  topBarVerticalPadding,
  mobileBreakpoint,
  firstDesktopBreakpoint,
  secondDesktopBreakpoint,
} from './navbarHelpers.tsx';
import {themePalette, ThemePalette} from '../../themePalette.ts';

interface NavbarProps {
  setSection: (section: string) => void;
  activeSection: string;
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const NavbarContainer = styled.div`
  -webkit-app-region: drag;
  display: flex;
  align-items: center;
  gap: ${topBarButtonGap}px;
  background-color: ${themePalette.dark.primaryContainer};
  height: ${topBarHeight}px;
  padding: ${topBarVerticalPadding}px ${topBarHorizontalPadding}px;
  contain: strict;
`;

const Logo = styled.img`
  height: 64px;
  max-width: 100%;
`;

const LogoContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const NavLinksDesktop = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

const NavItem = styled.li`
  color: ${themePalette.dark.onPrimaryContainer};
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #00606d;
    border-radius: 5px;
    color: ${themePalette.dark.onPrimaryContainer};
  }

  &.active {
    background-color: ${themePalette.dark.primary};
    border-radius: 5px;
    color: ${themePalette.dark.onPrimary};
  }

  @media (max-width: ${firstDesktopBreakpoint}px) {
    font-size: 14px;
    padding: 10px 10px;
  }

  @media (max-width: ${secondDesktopBreakpoint}px) {
    span {
      display: none;
    }
    height: 48px;
    width: 48px;
    justify-content: center;
    padding: 0;
    margin: 0 10px;

    &:hover {
      border-radius: 16px;
    }

    &.active {
      border-radius: 16px;
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 24px;

  @media (max-width: ${secondDesktopBreakpoint}px) {
    margin: 0;
  }
`;

const DiscordButton = styled.a`
  display: flex;
  align-items: center;
  color: white;
  background-color: #6e78f7;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  margin-left: auto;

  &:hover {
    background-color: #5865f2;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }

  @media (max-width: ${firstDesktopBreakpoint}px) {
    font-size: 14px;
    padding: 10px 10px;
  }
`;

const BurgerIcon = styled(FontAwesomeIcon)`
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: ${themePalette.dark.onPrimaryContainer};
  margin-left: auto;

  @media (max-width: ${mobileBreakpoint}px) {
    display: block;
  }
`;

const Navbar: React.FC<NavbarProps> = ({setSection, activeSection, isMenuOpen, toggleMenu}) => {
  const handleSectionChange = (newSection) => {
    setSection(newSection);
  };

  return (
    <NavbarContainer>
      <LogoContainer>
        <Logo src={require('./logoUF.png')} alt="Logo" />
      </LogoContainer>

      <NavLinksDesktop>
        <NavItem onClick={() => handleSectionChange('TEAMS')} className={activeSection === 'TEAMS' ? 'active' : ''}>
          <StyledIcon icon={faPeopleGroup} />
          <span>Teams</span>
        </NavItem>
        <NavItem onClick={() => handleSectionChange('PLAYERS')} className={activeSection === 'PLAYERS' ? 'active' : ''}>
          <StyledIcon icon={faPersonRunning} />
          <span>Players</span>
        </NavItem>
        <NavItem
          onClick={() => handleSectionChange('SCHEDULE')}
          className={activeSection === 'SCHEDULE' ? 'active' : ''}
        >
          <StyledIcon icon={faCalendarDays} />
          <span>Schedule</span>
        </NavItem>
        <NavItem onClick={() => handleSectionChange('TOTW')} className={activeSection === 'TOTW' ? 'active' : ''}>
          <StyledIcon icon={faAward} />
          <span>Team of the Week</span>
        </NavItem>
        <NavItem
          onClick={() => handleSectionChange('PLAY_OFF')}
          className={activeSection === 'PLAY_OFF' ? 'active' : ''}
        >
          <StyledIcon icon={faStar} />
          <span>Play Off</span>
        </NavItem>
        <NavItem
          onClick={() => handleSectionChange('ADMIN_PANEL')}
          className={activeSection === 'ADMIN_PANEL' || activeSection === 'LOGIN' ? 'active' : ''}
        >
          <StyledIcon icon={faScrewdriverWrench} />
          <span>Admin Panel</span>
        </NavItem>
        <NavItem
          onClick={() => handleSectionChange('LEADERBOARD')}
          className={activeSection === 'LEADERBOARD' ? 'active' : ''}
        >
          <StyledIcon icon={faRankingStar} />
          <span>Leaderboard</span>
        </NavItem>
      </NavLinksDesktop>

      <DiscordButton href="https://discord.com/invite/My6XSb4tS3">
        <StyledIcon icon={faDiscord} />
        Join our Discord
      </DiscordButton>
      <BurgerIcon icon={isMenuOpen ? faTimes : faBars} onClick={toggleMenu} />
    </NavbarContainer>
  );
};

export default Navbar;
