import React, {useEffect, useMemo, useState} from 'react';
import TeamCard from '../components/teamCard.tsx';
import SpecificTeamPage from './SpecificTeamPage';
import styled from 'styled-components';
import {themePalette} from '../themePalette.ts';
import {TeamInfo} from '../types/teams.ts';
import {fetchTeamsPage} from '../actions/teams.ts';

const TeamPageContainer = styled.div`
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  flex: 1;
  overflow-y: auto;
  white-space: nowrap;
  max-height: calc(100vh - 52px);

  @media (max-width: 980px) {
    flex: 0 0 auto;
  }

  @media (max-width: 850px) {
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const SidebarItem = styled.div.attrs<{$isActive: boolean}>((props) => ({
  $isActive: props.$isActive || false,
}))`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: ${(props) =>
    props.$isActive ? themePalette.dark.surfaceContainerHighest : themePalette.dark.background};

  &:hover {
    background-color: ${themePalette.dark.surfaceContainerHigh};
  }
`;

const SidebarItemText = styled.span`
  margin-right: 10px;

  @media (max-width: 980px) {
    display: none;
  }
`;

const Content = styled.div<{$logoUrl: string | null}>`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  z-index: 1; /* Ensure content is above the background */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${(props) => (props.$logoUrl ? `url(${props.$logoUrl})` : 'none')};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: luminosity;
    opacity: 0.1;
    z-index: -1; /* Place the background behind the content */
  }
`;

const TeamCardContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;

  @media (max-width: 850px) {
    position: static;
    transform: none;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
  }
`;

const TeamLogo = styled.img`
  width: 60px;
  height: auto;
  max-height: 100%;
  object-fit: contain;
`;

const sortTeams = (teams: TeamInfo[]) => {
  return teams.slice().sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

const TeamsPage = () => {
  const [teams, setTeams] = useState<TeamInfo[]>([]);
  const [isSpecificTeam, setIsSpecificTeam] = useState<boolean>(false);
  const [specificTeam, setSpecificTeam] = useState<TeamInfo | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const handleTeamClick = (clickedTeamShortName: string) => {
    const teamClicked = teams.find((team) => team.shortName === clickedTeamShortName);
    setSpecificTeam(teamClicked || null);
    setIsSpecificTeam(true);
  };

  useEffect(() => {
    fetchTeamsPage(setTeams);
  }, []);

  useEffect(() => {
    if (teams.length > 0) {
      setActiveTab(sortTeams(teams)[0].shortName);
    }
  }, [teams]);

  const activeTeam = useMemo(() => {
    return teams.find((team) => team.shortName === activeTab);
  }, [teams, activeTab]);

  const logoUrl = useMemo(() => {
    if (activeTeam) {
      return `/teamLogos/${activeTeam.logoFileName === 'Bastard_München.png' ? 'Bastard_Munchen.png' : activeTeam.logoFileName}`;
    }
    return null;
  }, [activeTeam]);

  if (isSpecificTeam && specificTeam) {
    return (
      <SpecificTeamPage
        teamName={specificTeam.name}
        teamLogoName={specificTeam.logoFileName}
        teamShortName={specificTeam.shortName}
        teamPlayers={specificTeam.players}
        onClick={() => setIsSpecificTeam(false)}
      />
    );
  }
  return (
    <TeamPageContainer>
      <Sidebar>
        {sortTeams(teams).map((team, index) => (
          <SidebarItem
            $isActive={activeTab === team.shortName}
            key={index}
            onClick={() => setActiveTab(team.shortName)}
          >
            <SidebarItemText>{team.name}</SidebarItemText>
            <TeamLogo
              src={`/teamLogos/${team.logoFileName === 'Bastard_München.png' ? 'Bastard_Munchen.png' : team.logoFileName}`}
              alt={`${team.name} Logo`}
            />
          </SidebarItem>
        ))}
      </Sidebar>
      <Content $logoUrl={logoUrl}>
        <TeamCardContainer>
          {teams
            .filter((team) => !activeTab || team.shortName === activeTab)
            .map((team, index) => (
              <TeamCard
                key={index}
                teamName={team.name}
                shortName={team.shortName}
                players={team.players}
                foundationDate={team.foundationDate}
                totalCost={team.totalCost}
                logoFileName={team.logoFileName}
                leaderboardPosition={team.leaderboardPosition}
                onClick={handleTeamClick}
              />
            ))}
        </TeamCardContainer>
      </Content>
    </TeamPageContainer>
  );
};

export default TeamsPage;
