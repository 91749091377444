// TeamOfTheWeekPage.js
import React, {useEffect, useState} from 'react';
import './index.css'; // Importa il tuo file CSS
import TeamOfTheWeekCard from '../../components/teamOfTheWeekCard.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAward} from '@fortawesome/free-solid-svg-icons';

import { fetchTOTW } from '../../actions/totw.ts';

const TeamOfTheWeekPage = () => {
  const [teamOfTheWeeks, setTeamOfTheWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [title, setTitle] = useState('Team of the Week');
  useEffect(() => {
    fetchTOTW(setTeamOfTheWeeks, setSelectedWeek);
  }, []);

  useEffect(() => {
    if (selectedWeek === 'TOTS') setTitle('Team of The Season');
    else if (selectedWeek === 'TOTP') setTitle('Team of the PlayOff');
    else setTitle('Team of the Week ' + selectedWeek);
  }, [selectedWeek, title]);

  return (
    <div className="team-of-the-week-page">
      <div className="title-header">
        <h2 className="title-text">
          <FontAwesomeIcon icon={faAward} className="fa-icon2" />
          {title}
        </h2>
      </div>
      <div className="week-selector">
        <label></label>
        <select value={selectedWeek} onChange={(e) => setSelectedWeek(e.target.value)}>
          {teamOfTheWeeks.map((week) => (
            <option key={week.weekNumber} value={week.weekNumber}>
              Team of the Week {week.weekNumber}
            </option>
          ))}
        </select>
      </div>
      <div className="team-of-the-week-cards-container">
        {teamOfTheWeeks
          .filter((week) => {
            if (selectedWeek !== 'TOTS' && selectedWeek !== 'TOTP') {
              return week.weekNumber === parseInt(selectedWeek);
            } else {
              return week.weekNumber === selectedWeek;
            }
          })
          .map((week) => (
            <div key={week.weekNumber} className="team-of-the-week-container">
              {week.players.map((player) => (
                <div className="tmp-totw-card" key={player.name}>
                  <TeamOfTheWeekCard
                    key={player.name}
                    role={player.role}
                    teamLogo={player.teamLogo}
                    playerAvatar={player.avatar}
                    playerName={player.name}
                    selectedWeek={selectedWeek}
                  />
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TeamOfTheWeekPage;
