import React, {useState} from 'react';
import Match from '../components/Match';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {themePalette} from '../themePalette.ts';
import {Matchday, MatchdayDate} from '../types/matchday.ts';

const Container = styled.div`
  margin: 30px;
  width: 90%;
  max-width: 1000px;
  text-align: center;
`;

const MatchdayContainer = styled.div`
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${themePalette.dark.surfaceContainerHigh};
  color: ${themePalette.dark.onSurface};
  padding: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    transition: 0.2s;
    box-shadow: 0px 0px 12px 7px #222222e6;
  }
`;

const MatchdayTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Matches = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0px;
`;

const ChangeMatchdayDiv = styled.div<{$active?: boolean}>`
  width: 50px;
  height: 50px;
  cursor: ${(props) => (props.$active ? 'pointer' : 'default')};
  opacity: ${(props) => (props.$active ? '1' : '0.5')};
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChangeMatchdayIcon = styled(FontAwesomeIcon)``;

const Dropdown = styled.select`
  font-size: 1rem;
  padding: 5px;
  margin-left: 10px;
  background-color: ${themePalette.dark.surfaceContainerHigh};
  color: ${themePalette.dark.onSurface};
  border-radius: 5px;
  border: 1px solid ${themePalette.dark.onSurface};
  cursor: pointer;
  &:focus {
    outline: none;
    border-color: ${themePalette.dark.primary};
  }
`;

interface FixturesPageProps {
  groupedMatches: Record<number, Matchday[]>;
  matchdayDates: MatchdayDate[];
}

const FixturesPage: React.FC<FixturesPageProps> = ({groupedMatches, matchdayDates}) => {
  const [activeMatchdayTab, setActiveMatchdayTab] = useState(1);

  const goToPreviousMatchday = () => {
    if (!activeMatchdayTab) return;
    if (activeMatchdayTab === 1) return;
    setActiveMatchdayTab(activeMatchdayTab - 1);
  };

  const goToNextMatchday = () => {
    if (!activeMatchdayTab) return;
    if (activeMatchdayTab === Object.keys(groupedMatches).length) return;
    setActiveMatchdayTab(activeMatchdayTab + 1);
  };

  const handleMatchdayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveMatchdayTab(Number(e.target.value));
  };

  const activeMatchdayDate = matchdayDates.find((date) => date.matchday === activeMatchdayTab)?.date || null;

  return (
    <Container>
      <MatchdayContainer key={activeMatchdayTab}>
        <MatchdayTitle>
          <ChangeMatchdayDiv onClick={goToPreviousMatchday} $active={activeMatchdayTab !== 1}>
            <ChangeMatchdayIcon icon={faArrowLeft} />
          </ChangeMatchdayDiv>
          <Title>
            Matchday {activeMatchdayTab} - {activeMatchdayDate}
            <Dropdown onChange={handleMatchdayChange} value={activeMatchdayTab}>
              {Object.keys(groupedMatches).map((matchday) => (
                <option key={matchday} value={matchday}>
                  Matchday {matchday}
                </option>
              ))}
            </Dropdown>
          </Title>
          <ChangeMatchdayDiv
            onClick={goToNextMatchday}
            $active={activeMatchdayTab !== Object.keys(groupedMatches).length}
          >
            <ChangeMatchdayIcon icon={faArrowRight} />
          </ChangeMatchdayDiv>
        </MatchdayTitle>
        <Matches>
          {groupedMatches[activeMatchdayTab] &&
            groupedMatches[activeMatchdayTab].map((match) => (
              <Match
                key={match._id}
                teamAName={match.teamA}
                teamBName={match.teamB}
                matchStatus={match.status}
                result={match.result}
                statsTeamA={match.statsA}
                statsTeamB={match.statsB}
                replayLink={match.replay}
                wildcard={match.wildcard}
                defwin={match.defwin}
                time={match.time}
              />
            ))}
        </Matches>
      </MatchdayContainer>
    </Container>
  );
};

export default FixturesPage;
