import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {themePalette} from '../themePalette.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDays} from '@fortawesome/free-solid-svg-icons';
import FixturesPage from './fixturesPage.tsx';
import TeamSchedulePage from './teamSchedulePage.tsx';
import {Matchday, MatchdayDate} from '../types/matchday.ts';
import {TeamInfo} from '../types/teams.ts';
import {fetchMatchdays} from '../actions/matchdays.ts';
import {fetchTeamsPage} from '../actions/teams.ts';

const SchedulePageContainer = styled.div`
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  flex: 1;
  overflow-y: auto;
  white-space: nowrap;
  max-height: calc(100vh - 52px);
  @media (max-width: 1170px) {
    flex: 0 0 auto;
  }

  @media (max-width: 850px) {
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const SidebarItem = styled.div.attrs<{$isActive: boolean}>((props) => ({
  $isActive: props.$isActive || false,
}))`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1170px) {
    justify-content: flex-end;
  }

  background-color: ${(props) =>
    props.$isActive ? themePalette.dark.surfaceContainerHighest : themePalette.dark.background};

  &:hover {
    background-color: ${themePalette.dark.surfaceContainerHigh};
  }
`;

const SidebarItemText = styled.span`
  margin-right: 10px;

  @media (max-width: 1170px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 4;
  display: flex;
  justify-content: center;
`;

const TeamLogo = styled.img`
  width: 60px;
  height: auto;
  max-height: 100%;
  object-fit: contain;

  @media (max-width: 850px) {
    width: 48px;
  }
`;

const FixturesIcon = styled(FontAwesomeIcon)`
  width: 40px;
  height: 60px;
  margin-right: 10px;
  margin-left: 10px;
  max-height: 100%;
  object-fit: contain;

  @media (max-width: 850px) {
    width: 32px;
    height: 48px;
  }
`;

const SchedulePage = () => {
  const [teams, setTeams] = useState<TeamInfo[]>([]);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const [matches, setMatches] = useState<Matchday[]>([]);
  const [matchdayDates, setMatchdayDates] = useState<MatchdayDate[]>([]);

  useEffect(() => {
    fetchMatchdays(setMatches, setMatchdayDates);
    fetchTeamsPage(setTeams);
    setActiveTab('fixtures');
  }, []);

  const groupedMatches = matches.reduce(
    (acc, match) => {
      const matchday = match.matchday;
      if (!acc[matchday]) {
        acc[matchday] = [];
      }
      acc[matchday].push(match);
      return acc;
    },
    {} as Record<number, Matchday[]>
  );

  return (
    <SchedulePageContainer>
      <Sidebar>
        <SidebarItem $isActive={activeTab === 'fixtures'} onClick={() => setActiveTab('fixtures')}>
          <SidebarItemText>Fixtures</SidebarItemText>
          <FixturesIcon icon={faCalendarDays} />
        </SidebarItem>
        {teams
          .slice()
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((team, index) => (
            <SidebarItem
              $isActive={activeTab === team.shortName}
              key={index}
              onClick={() => setActiveTab(team.shortName)}
            >
              <SidebarItemText>{team.name}</SidebarItemText>
              <TeamLogo
                src={`/teamLogos/${team.logoFileName === 'Bastard_München.png' ? 'Bastard_Munchen.png' : team.logoFileName}`}
                alt={`${team.name} Logo`}
              />
            </SidebarItem>
          ))}
      </Sidebar>
      <Content>
        {activeTab === 'fixtures' && <FixturesPage groupedMatches={groupedMatches} matchdayDates={matchdayDates} />}
        {activeTab !== 'fixtures' &&
          teams
            .filter((team) => !activeTab || team.shortName === activeTab)
            .map((team) => (
              <TeamSchedulePage
                key={team.name}
                groupedMatches={groupedMatches}
                matchdayDates={matchdayDates}
                team={team}
              />
            ))}
      </Content>
    </SchedulePageContainer>
  );
};

export default SchedulePage;
