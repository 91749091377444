// PlayerCard.js
import React from 'react';
import {AutoTextSize} from 'auto-text-size';
import {faCoins, faFutbol, faHandshake, faMitten, faStopwatch, faUsers} from '@fortawesome/free-solid-svg-icons'; // Sostituisci con le icone appropriate
import styled from 'styled-components';
import {CardInfo, InfoBox, InfoItemContainer, InfoItem, InfoIcon, InfoItemSpan} from './card.tsx';

const Card = styled.div`
  background-color: #333;
  background: linear-gradient(151deg, #333 23.32%, rgba(33, 12, 120, 0) 137.29%);
  color: white;
  border: 1px solid #333;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 0px 10px 5px #222222e6;
  text-align: center;
  min-width: 330px;
  max-width: 330px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
`;

const LeftEmptyBox = styled.div`
  width: 60px;
`;

const PlayerName = styled.div`
  border-radius: 6px;
  padding-left: 2px;
  padding-right: 2px;
  color: white;

  &:hover {
    transition: 0.2s;
    transform: translateY(-3px);
    box-shadow: 0px 0px 10px 5px #222222e6;
    background-color: #5b5a5a;
    color: white;
  }
`;

const StyledAutoTextSize = styled(AutoTextSize)`
  font-weight: bolder;
  font-size: 22px;
`;

const RightLogoBox = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
`;

const TeamLogo = styled.img`
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
`;

const TeamNameSpan = styled.span`
  font-size: 0.9rem;
  margin-left: 5px;
  font-weight: bold;
`;

const costColors = {
  1: '#a3bdba',
  2: '#fdd550',
  3: '#cc87eb',
};

const PlayerCard = ({playerName, team, goals, assists, cleansheets, playtime, cost, teamLogoFileName}) => {
  /* const teamLogoPath = require(`/teamLogos/${teamLogoFileName}`); */

  let actualTeamLogoFileName = teamLogoFileName;
  if (actualTeamLogoFileName === 'Bastard_München.png') actualTeamLogoFileName = 'Bastard_Munchen.png';

  return (
    <Card>
      <TopRow>
        <LeftEmptyBox />
        <PlayerName>
          <StyledAutoTextSize maxFontSizePx={18} minFontSizePx={12} mode="shrink" fontSizePrecisionPx={1}>
            {playerName}
          </StyledAutoTextSize>
        </PlayerName>
        <RightLogoBox>
          {teamLogoFileName && <TeamLogo src={`/teamLogos/${actualTeamLogoFileName}`} alt={`${team} Logo`} />}
        </RightLogoBox>
      </TopRow>

      <CardInfo>
        <InfoBox>
          <InfoItem>
            <InfoItemContainer>
              <InfoIcon icon={faUsers} />
              <TeamNameSpan>{team}</TeamNameSpan>
            </InfoItemContainer>
          </InfoItem>
          <InfoItemContainer>
            <InfoItem>
              <InfoIcon icon={faFutbol} />
              <InfoItemSpan>{goals}</InfoItemSpan>
            </InfoItem>
            <InfoItem>
              <InfoIcon icon={faHandshake} />
              <InfoItemSpan>{assists}</InfoItemSpan>
            </InfoItem>
            <InfoItem>
              <InfoIcon icon={faMitten} />
              <InfoItemSpan>{cleansheets}</InfoItemSpan>
            </InfoItem>
            <InfoItem>
              <InfoIcon icon={faStopwatch} />
              <InfoItemSpan>{playtime}</InfoItemSpan>
            </InfoItem>
            <InfoItem color={costColors[Math.floor(cost)]}>
              <InfoIcon icon={faCoins} />
              <InfoItemSpan>{cost}</InfoItemSpan>
            </InfoItem>
          </InfoItemContainer>
        </InfoBox>
      </CardInfo>
    </Card>
  );
};

export default PlayerCard;
