export interface ThemePalette {
  dark: {
    primary: string;
    surfaceTint: string;
    onPrimary: string;
    primaryContainer: string;
    onPrimaryContainer: string;
    secondary: string;
    onSecondary: string;
    secondaryContainer: string;
    onSecondaryContainer: string;
    tertiary: string;
    onTertiary: string;
    tertiaryContainer: string;
    onTertiaryContainer: string;
    error: string;
    onError: string;
    errorContainer: string;
    onErrorContainer: string;
    background: string;
    onBackground: string;
    surface: string;
    onSurface: string;
    surfaceVariant: string;
    onSurfaceVariant: string;
    outline: string;
    outlineVariant: string;
    shadow: string;
    scrim: string;
    inverseSurface: string;
    inverseOnSurface: string;
    inversePrimary: string;
    primaryFixed: string;
    onPrimaryFixed: string;
    primaryFixedDim: string;
    onPrimaryFixedVariant: string;
    secondaryFixed: string;
    onSecondaryFixed: string;
    secondaryFixedDim: string;
    onSecondaryFixedVariant: string;
    tertiaryFixed: string;
    onTertiaryFixed: string;
    tertiaryFixedDim: string;
    onTertiaryFixedVariant: string;
    surfaceDim: string;
    surfaceBright: string;
    surfaceContainerLowest: string;
    surfaceContainerLow: string;
    surfaceContainer: string;
    surfaceContainerHigh: string;
    surfaceContainerHighest: string;
    successContainer: string;
  };
}

export const themePalette: ThemePalette = {
  dark: {
    primary: '#82D3E0',
    surfaceTint: '#82D3E0',
    onPrimary: '#00363D',
    primaryContainer: '#004F58',
    onPrimaryContainer: '#9EEFFD',
    secondary: '#B1CBD0',
    onSecondary: '#1C3438',
    secondaryContainer: '#334B4F',
    onSecondaryContainer: '#CDE7EC',
    tertiary: '#BAC6EA',
    onTertiary: '#24304D',
    tertiaryContainer: '#3B4664',
    onTertiaryContainer: '#DAE2FF',
    error: '#FFB4AB',
    onError: '#690005',
    errorContainer: '#93000A',
    onErrorContainer: '#FFDAD6',
    background: '#0E1415',
    onBackground: '#DEE3E5',
    surface: '#0E1415',
    onSurface: '#DEE3E5',
    surfaceVariant: '#3F484A',
    onSurfaceVariant: '#BFC8CA',
    outline: '#899294',
    outlineVariant: '#3F484A',
    shadow: '#000000',
    scrim: '#000000',
    inverseSurface: '#DEE3E5',
    inverseOnSurface: '#2B3133',
    inversePrimary: '#006874',
    primaryFixed: '#9EEFFD',
    onPrimaryFixed: '#001F24',
    primaryFixedDim: '#82D3E0',
    onPrimaryFixedVariant: '#004F58',
    secondaryFixed: '#CDE7EC',
    onSecondaryFixed: '#051F23',
    secondaryFixedDim: '#B1CBD0',
    onSecondaryFixedVariant: '#334B4F',
    tertiaryFixed: '#DAE2FF',
    onTertiaryFixed: '#0E1B37',
    tertiaryFixedDim: '#BAC6EA',
    onTertiaryFixedVariant: '#3B4664',
    surfaceDim: '#0E1415',
    surfaceBright: '#343A3B',
    surfaceContainerLowest: '#090F10',
    surfaceContainerLow: '#171D1E',
    surfaceContainer: '#1B2122',
    surfaceContainerHigh: '#252B2C',
    surfaceContainerHighest: '#303637',
    successContainer: '#556B2F'
  },
};
