import React, {useState, useEffect} from 'react';
import actions from '../../actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

import './index.css';
import PlayOffMatch from '../../components/PlayOffMatch';
import KnockOutMatchStats from '../../components/KnockOutMatchStats';

const PlayOffPage = () => {
  const [isMatchSelected, setIsMatchSelected] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [quarterFinals, setQuarterFinals] = useState(null);
  const [semiFinals, setSemiFinals] = useState(null);
  const [final, setFinal] = useState(null);
  const [loading, setLoading] = useState(true);

  const matches = {
    knockOutPhase: 'Prova',
    matches: [
      {
        teamA: 'Team A',
        statsA: [
          {
            player: 'Octopus',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
          {
            player: 'Cora',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
        ],
        teamB: 'Team B',
        statsB: [
          {
            player: 'Octopus',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
          {
            player: 'Cora',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
        ],
        result: '0-0',
      },
      {
        teamA: 'Team A',
        statsA: [
          {
            player: 'Octopus',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
          {
            player: 'Cora',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
        ],
        teamB: 'Team B',
        statsB: [
          {
            player: 'Octopus',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
          {
            player: 'Cora',
            playtime: 420,
            goal: 0,
            assist: 0,
            cleansheet: 0,
          },
        ],
        result: '0-0',
      },
    ],
  };

  const quarterFinalsDate = '04/03/2024';
  const semiFinalsDate = '04/03/2024';
  const finalsDate = '06/03/2024';

  //teamA, teamB, result
  /* const quarterFinals = [
        {
            teamA: "TestA",
            logoTeamA: "Potatoes_FC.png",
            teamB: "TestB",
            logoTeamB: "logoTeamB",
            result: "2-0",
        },
        {
            teamA: "TestA",
            logoTeamA: "logoTeamA",
            teamB: "TestB",
            logoTeamB: "logoTeamB",
            result: "2-0",
        },
    ];

    const semiFinals = [
        {
            teamA: "TestA",
            logoTeamA: "logoTeamA",
            teamB: "TestB",
            logoTeamB: "logoTeamB",
            result: "2-0",
        },
        {
            teamA: "TestA",
            logoTeamA: "logoTeamA",
            teamB: "TestB",
            logoTeamB: "logoTeamB",
            result: "2-0",
        },
    ];

    const final = [
        {
            teamA: "TestA",
            logoTeamA: "logoTeamA",
            teamB: "TestB",
            logoTeamB: "logoTeamB",
            result: "2-0",
        }
    ]; */

  useEffect(() => {}, [selectedMatch]);

  useEffect(() => {
    setLoading(true);
    //actions.fetchMatchdays(setMatches, setMatchdayDates);
    actions.fetchPlayoffMatches(setQuarterFinals, setSemiFinals, setFinal, setLoading);
  }, []);

  useEffect(() => {}, [quarterFinals, semiFinals, final]);

  const handleMatchClick = (clickedMatch) => {
    setSelectedMatch(clickedMatch); // Aggiornamento immediato
    setTimeout(() => {
      setIsMatchSelected(true);
    }, 500);
    //setIsMatchSelected(true);
    //console.log(clickedMatch.matches);
  };
  if (loading) {
    <div className="playoff-page-container">
      <div className="title-header">
        <h2 className="title-text">
          <FontAwesomeIcon icon={faStar} className="fa-icon2" />
          PlayOff
        </h2>
      </div>
    </div>;
  } else {
    return (
      <div className="playoff-page-container">
        <div className="title-header">
          <h2 className="title-text">
            <FontAwesomeIcon icon={faStar} className="fa-icon2" />
            PlayOff
          </h2>
        </div>

        <div className="playoff-board">
          <div className="quarter-finals">
            <div className="round-title">‎ QUARTER FINALS 🎖️</div>
            <div className="date">{quarterFinals[0].date}</div>
            <div className="round-matches">
              {quarterFinals.map((match) => {
                return (
                  <div className="playoff-match">
                    <PlayOffMatch
                      teamA={match.teamA}
                      logoTeamA={match.logoTeamA}
                      teamB={match.teamB}
                      logoTeamB={match.logoTeamB}
                      result={match.result}
                      knockOutPhase={'QUARTER FINAL 🎖️'}
                      matches={match.matches}
                      played={match.played}
                      matchClick={handleMatchClick}
                    />
                  </div>
                );
              })}
              {/* <div className='playoff-match'>
                            <PlayOffMatch matchClick={handleMatchClick} />
                        </div>

                        <div className='playoff-match'>
                            <PlayOffMatch matchClick={handleMatchClick} />
                        </div> */}
            </div>
          </div>
          <div className="semi-finals">
            <div className="round-title">‎ SEMI FINALS 🏅</div>
            <div className="date">{semiFinals[0].date}</div>
            <div className="round-matches">
              {/* <div className='playoff-match'>
                            <PlayOffMatch matchClick={handleMatchClick} />
                        </div>

                        <div className='playoff-match'>
                            <PlayOffMatch matchClick={handleMatchClick} />
                        </div> */}
              {semiFinals.map((match) => {
                return (
                  <div className="playoff-match">
                    <PlayOffMatch
                      teamA={match.teamA}
                      logoTeamA={match.logoTeamA}
                      teamB={match.teamB}
                      logoTeamB={match.logoTeamB}
                      result={match.result}
                      knockOutPhase={'SEMI FINAL 🏅'}
                      matches={match.matches}
                      played={match.played}
                      matchClick={handleMatchClick}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="final">
            <div className="round-title">‎ FINAL 🏆</div>
            <div className="date">{final[0].date}</div>
            <div className="round-matches">
              <div className="playoff-match">
                {/* <PlayOffMatch matchClick={handleMatchClick} /> */}
                {final.map((match) => {
                  return (
                    <div className="playoff-match">
                      <PlayOffMatch
                        teamA={match.teamA}
                        logoTeamA={match.logoTeamA}
                        teamB={match.teamB}
                        logoTeamB={match.logoTeamB}
                        result={match.result}
                        knockOutPhase={'FINAL 🏆'}
                        matches={match.matches}
                        played={match.played}
                        matchClick={handleMatchClick}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {isMatchSelected ? (
          <KnockOutMatchStats
            knockOutPhase={selectedMatch.knockOutPhase}
            matches={selectedMatch.matches}
            played={selectedMatch.played}
          />
        ) : null}
      </div>
    );
  }
};

export default PlayOffPage;
