import React from 'react';
import './index.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartSimple, faFutbol, faHandshake, faMitten, faStopwatch} from '@fortawesome/free-solid-svg-icons';

const KnockOutMatchStats = ({knockOutPhase, matches, played}) => {
  if (played && matches && matches.length !== 0) {
    return (
      <div className="knockout-container">
        <div className="knockout-title">{knockOutPhase}</div>

        {matches.map((match, matchIndex) => {
          return (
            <div key={matchIndex} className="knockout-stats">
              <div className="left-team">
                <div className="stats-title">
                  <FontAwesomeIcon icon={faChartSimple} className="stats-icon" />
                  {match.teamA}
                </div>
                <div className="stats-container2">
                  {match.statsA.map((singleStat, statIndex) => {
                    return (
                      <div key={statIndex} className="player-stats">
                        <p className="player-name">{singleStat.player}:</p>
                        {singleStat.goal ? (
                          <p>
                            {' '}
                            <FontAwesomeIcon icon={faFutbol} /> {singleStat.goal}G{' '}
                          </p>
                        ) : null}
                        {singleStat.assist ? (
                          <p>
                            {' '}
                            <FontAwesomeIcon icon={faHandshake} /> {singleStat.assist}A{' '}
                          </p>
                        ) : null}
                        {singleStat.cleansheet > 0 ? (
                          <p>
                            {' '}
                            <FontAwesomeIcon icon={faMitten} /> {singleStat.cleansheet}CS{' '}
                          </p>
                        ) : null}
                        <p>
                          {' '}
                          {'('}
                          <FontAwesomeIcon icon={faStopwatch} /> {singleStat.playtime}s{')'}{' '}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="knockout-result">{match.result}</div>
              <div className="right-team">
                <div className="stats-title">
                  <FontAwesomeIcon icon={faChartSimple} className="stats-icon" />
                  {match.teamB}
                </div>
                <div className="stats-container2">
                  {match.statsB.map((singleStat, statIndex) => {
                    return (
                      <div key={statIndex} className="player-stats">
                        <p className="player-name">{singleStat.player}:</p>
                        {singleStat.goal ? (
                          <p>
                            {' '}
                            <FontAwesomeIcon icon={faFutbol} /> {singleStat.goal}G{' '}
                          </p>
                        ) : null}
                        {singleStat.assist ? (
                          <p>
                            {' '}
                            <FontAwesomeIcon icon={faHandshake} /> {singleStat.assist}A{' '}
                          </p>
                        ) : null}
                        {singleStat.cleansheet > 0 ? (
                          <p>
                            {' '}
                            <FontAwesomeIcon icon={faMitten} /> {singleStat.cleansheet}CS{' '}
                          </p>
                        ) : null}
                        <p>
                          {' '}
                          {'('}
                          <FontAwesomeIcon icon={faStopwatch} /> {singleStat.playtime}s{')'}{' '}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default KnockOutMatchStats;
