import React from 'react';

import styled from 'styled-components';
import {themePalette} from '../themePalette.ts';
import {LeagueLeaderboardType} from '../types/leaderboard.ts';

const TableContainer = styled.div`
  width: 90%;
  max-width: 1000px;

  @media (max-width: 700px) {
    width: 97%;
  }
`;

const ScrollingTableContainer = styled.div`
  overflow-x: auto; //Use overflow-x for horizontal scrolling
  white-space: nowrap;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${themePalette.dark.surface};
`;

const TableHeader = styled.thead`
  background-color: ${themePalette.dark.surfaceContainerHigh};
  color: ${themePalette.dark.onSurface};
`;

const TableRow = styled.tr`
  color: ${themePalette.dark.onSurfaceVariant};
  background-color: ${themePalette.dark.surfaceVariant};

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  text-align: center;
  border-bottom: 0.5px solid ${themePalette.dark.onSecondaryContainer};
  font-size: 0.9rem;

  @media (max-width: 615px) {
    &:nth-child(n + 4):nth-child(-n + 8) {
      display: none;
    }
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  gap: 20px;

  @media (max-width: 580px) {
    flex-direction: column;
    align-items: left;
    margin-left: 80px;
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
`;

const TeamNameLogo = styled.div`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;

  @media (max-width: 880px) {
    margin-left: 0px;
  }

  @media (max-width: 700px) {
    font-size: 0.9rem;
    font-weight: 550;
  }
`;

const TeamLogo = styled.img`
  vertical-align: middle;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

function renderTeamNameLogo(teamName: string) {
  let teamLogoFileName = teamName.replace(/\s/g, '_');
  if (teamLogoFileName === 'Bastard_München') teamLogoFileName = 'Bastard_Munchen';

  return (
    <TeamNameLogo>
      <TeamLogo src={`/teamLogos/${teamLogoFileName}.png`} alt={`${teamName} Logo`} />
      {teamName}
    </TeamNameLogo>
  );
}

interface LeagueLeaderboardProps {
  classifica: LeagueLeaderboardType[];
}

const LeagueLeaderboard: React.FC<LeagueLeaderboardProps> = ({classifica}) => {
  const colors = {
    'Playoff Semifinals': '#4185F3',
    'Playoff Quarterfinals': '#FB7B18',
    'Play-In': '#33A854',
    Out: themePalette.dark.surfaceVariant,
  };

  return (
    <TableContainer>
      <ScrollingTableContainer>
        {classifica.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <tr>
                  <TableCell>Pos</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>G</TableCell>
                  <TableCell>V</TableCell>
                  <TableCell>N</TableCell>
                  <TableCell>P</TableCell>
                  <TableCell>+/-</TableCell>
                  <TableCell>DR</TableCell>
                  <TableCell>Points</TableCell>
                </tr>
              </TableHeader>
              <tbody>
                {classifica.map((squadra, index) => (
                  <TableRow
                    key={index}
                    style={{
                      boxShadow: `inset 7px 0px 0px ${
                        colors[
                          index < 2
                            ? 'Playoff Semifinals'
                            : index < 4
                              ? 'Playoff Quarterfinals'
                              : index < 8
                                ? 'Play-In'
                                : 'Out'
                        ]
                      }`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{renderTeamNameLogo(squadra.team)}</TableCell>
                    <TableCell>{squadra.matches}</TableCell>
                    <TableCell>{squadra.win}</TableCell>
                    <TableCell>{squadra.draw}</TableCell>
                    <TableCell>{squadra.loss}</TableCell>
                    <TableCell>{squadra.scoredtaken}</TableCell>
                    <TableCell>{squadra.dr}</TableCell>
                    <TableCell>{squadra.points}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
            <LegendContainer>
              {Object.entries(colors).map(([category, color]) => (
                <LegendItem key={category}>
                  <LegendColor style={{backgroundColor: color}} />
                  <span>{category}</span>
                </LegendItem>
              ))}
            </LegendContainer>
          </>
        ) : (
          <p>No data available for League Leaderboard at the moment.</p>
        )}
      </ScrollingTableContainer>
    </TableContainer>
  );
};

export default LeagueLeaderboard;
