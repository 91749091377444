import {Totw} from '../types/totw';

const fetchTOTW = async (setTeamOfTheWeeks: (totws: Totw[]) => void, setSelectedWeek: (weekNumber: number) => void) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/team/teamOfTheWeeks', {
      headers: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (response.ok) {
      const data: Totw[] = await response.json();
      setTeamOfTheWeeks(data);
      // Imposta di default l'ultima settimana disponibile
      if (data.length > 0) {
        setSelectedWeek(data[data.length - 1].weekNumber);
      }
    } else {
      console.error('Error fetching Team Of The Week');
    }
  } catch (error) {
    console.error('Error fetching Team Of The Week:', error);
  }
};

export {fetchTOTW};
