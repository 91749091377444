import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {themePalette} from '../themePalette.ts';
import LeagueLeaderboard from './leagueLeaderboard.tsx';
import GoalLeaderboard from './goalLeaderboard.tsx';
import AssistLeaderboard from './assistLeaderboard.tsx';
import {fetchLeaderboards} from '../actions/leaderboards.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFutbol, faHandshake, faMitten, faTrophy} from '@fortawesome/free-solid-svg-icons';
import CleansheetLeaderboard from './cleansheetLeaderboard.tsx';
import {
  AssistLeaderboardType,
  CleansheetLeaderboardType,
  GoalLeaderboardType,
  LeagueLeaderboardType,
} from '../types/leaderboard.ts';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${themePalette.dark.onBackground};
  font-family: 'Roboto', sans-serif;
`;

const TopNavBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const NavItem = styled.div.attrs<{$isActive: boolean}>((props) => ({
  $isActive: props.$isActive || false,
}))`
  padding: 25px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  background-color: ${(props) =>
    props.$isActive ? themePalette.dark.surfaceContainerHighest : themePalette.dark.background};

  &:hover {
    background-color: ${themePalette.dark.surfaceContainerHigh};
  }

  @media (max-width: 580px) {
    padding: 15px 10px;
  }
`;

const NavItemText = styled.span.attrs<{$isActive: boolean}>((props) => ({
  $isActive: props.$isActive || false,
}))`
  margin-left: 10px;

  @media (max-width: 430px) {
    display: ${(props) => (props.$isActive ? 'flex' : 'none')};
  }
`;

const Leaderboard = () => {
  const [activeTab, setActiveTab] = useState('LeagueLeaderboard');
  const [leagueLeaderboardData, setLeagueLeaderboardData] = useState<LeagueLeaderboardType[]>([]);
  const [goalLeaderboardData, setGoalLeaderboardData] = useState<GoalLeaderboardType[]>([]);
  const [assistLeaderboardData, setAssistLeaderboardData] = useState<AssistLeaderboardType[]>([]);
  const [cleansheetLeaderboardData, setCleansheetLeaderboardData] = useState<CleansheetLeaderboardType[]>([]);

  useEffect(() => {
    fetchLeaderboards(
      setLeagueLeaderboardData,
      setGoalLeaderboardData,
      setAssistLeaderboardData,
      setCleansheetLeaderboardData
    );
  }, []);

  const renderComponent = () => {
    switch (activeTab) {
      case 'LeagueLeaderboard':
        return <LeagueLeaderboard classifica={leagueLeaderboardData} />;
      case 'GoalLeaderboard':
        return <GoalLeaderboard classifica={goalLeaderboardData} />;
      case 'AssistLeaderboard':
        return <AssistLeaderboard classifica={assistLeaderboardData} />;
      case 'CleansheetLeaderboard':
        return <CleansheetLeaderboard classifica={cleansheetLeaderboardData} />;
      default:
        return <LeagueLeaderboard classifica={leagueLeaderboardData} />;
    }
  };

  return (
    <PageContainer>
      <TopNavBar>
        <NavItem $isActive={activeTab === 'LeagueLeaderboard'} onClick={() => setActiveTab('LeagueLeaderboard')}>
          <FontAwesomeIcon icon={faTrophy} />
          <NavItemText $isActive={activeTab === 'LeagueLeaderboard'}>League</NavItemText>
        </NavItem>
        <NavItem $isActive={activeTab === 'GoalLeaderboard'} onClick={() => setActiveTab('GoalLeaderboard')}>
          <FontAwesomeIcon icon={faFutbol} />
          <NavItemText $isActive={activeTab === 'GoalLeaderboard'}>Goals</NavItemText>
        </NavItem>
        <NavItem $isActive={activeTab === 'AssistLeaderboard'} onClick={() => setActiveTab('AssistLeaderboard')}>
          <FontAwesomeIcon icon={faHandshake} />
          <NavItemText $isActive={activeTab === 'AssistLeaderboard'}>Assists</NavItemText>
        </NavItem>
        <NavItem
          $isActive={activeTab === 'CleansheetLeaderboard'}
          onClick={() => setActiveTab('CleansheetLeaderboard')}
        >
          <FontAwesomeIcon icon={faMitten} />
          <NavItemText $isActive={activeTab === 'CleansheetLeaderboard'}>Cleansheets</NavItemText>
        </NavItem>
      </TopNavBar>
      {renderComponent()}
    </PageContainer>
  );
};

export default Leaderboard;
